import React from 'react';
import './article.css';

const Article = ({ imgUrl, date, text, linkUrl }) => (
  <a target="_blank" rel="noreferrer" href={linkUrl} className="fgc__blog-container_article">
    <div className="fgc__blog-container_article-image">
      <img src={imgUrl} alt="blog_image" />
    </div>
    <div className="fgc__blog-container_article-content">
      <div>
        <p>{date}</p>
        <h3>{text}</h3>
      </div>
      <p>Read Full Article</p>
    </div>
  </a>
);

export default Article;
