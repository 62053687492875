import React from 'react';
import './features.css';

const Features = () => (
  <div className="fgc__features fgc__margin" id="features">
    <div className="fgc__features-heading">
      <h1 className="gradient__text">The Future is Now and You Just Need to Realize It.</h1>
      <h1 className="gradient__text">Step into the Metaverse Today. Make it Happen.</h1>
    </div>
  </div>
);

export default Features;
