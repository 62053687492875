import React from 'react';
import ai from '../../assets/ai.png';
import instagram from '../../assets/social/instagram.png';
import twitter from '../../assets/social/twitter.png';
import youtube from '../../assets/social/youtube.png';
import facebook from '../../assets/social/facebook.png';
import discord from '../../assets/social/discord.png';
import './header.css';

const Header = () => (
  <div className="fgc__header section__padding">
    <div className="fgc__header-content">
      <h1>Use your NFTs to </h1>
      <h1 className="gradient__text">earn our token FTC </h1>
      <h1>and convert it to real money.</h1>

      <div className="fgc__header-content__people">
        <a href="https://forgottenchain.com/img/assets/pdf/whitepaper.pdf" className="h_button" target="_blank" rel="noreferrer">White Paper</a>
        <a href="https://sale.forgottenchain.com/" className="h_button" target="_blank" rel="noreferrer">Marketplace</a>
      </div>
      <div className="socialContainer">
        <div className="social">
          <a href="https://www.instagram.com/forgottenchain/" target="_blank" rel="noreferrer">
            <img src={instagram} />
          </a>
          <a href="https://twitter.com/Forgotten_Chain" target="_blank" rel="noreferrer">
            <img src={twitter} />
          </a>
          <a href="https://www.youtube.com/c/ForgottenChain" target="_blank" rel="noreferrer">
            <img src={youtube} />
          </a>
          <a href="https://www.facebook.com/forgottenchain" target="_blank" rel="noreferrer">
            <img src={facebook} />
          </a>
          <a href="https://discord.gg/PVtpZPEKsJ" target="_blank" rel="noreferrer">
            <img src={discord} />
          </a>
        </div>
      </div>
    </div>
    <div className="fgc__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;
