import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-scroll';
import './navbar.css';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import Web3ReactConnectionComponent from '../wallet/Wallet';
import logo from '../../logo.png';

const getLibrary = (provider) => {
  const library = new Web3Provider(provider, 'any');
  library.pollingInterval = 15000;
  return library;
};

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div id="home" className="fgc__navbar">
      <div className="fgc__navbar-links">
        <div className="fgc__navbar-links_logo">
          <img src={logo} />
        </div>
        <div className="fgc__navbar-links_container">
          <p><Link to="wfgc" spy smooth duration={500}>Tokenomics</Link></p>
          <p><a href="https://solidity.finance/audits/ForgottenCoinICO/" target="_blank" rel="noreferrer">Audit</a></p>
          <p><Link to="possibility" spy smooth duration={500}>NFT</Link></p>
          <p><Link to="blog" spy smooth duration={500}>Media</Link></p>
          <p><Link to="team" spy smooth duration={500}>Team</Link></p>
          <p><Link to="roadmap" spy smooth duration={500}>Roadmap</Link></p>
        </div>
      </div>
      <div className="fgc__navbar-sign">
        <Web3ReactProvider getLibrary={getLibrary}>
          <div className="flex space-x-3">
            <Web3ReactConnectionComponent />
          </div>
        </Web3ReactProvider>
      </div>
      <div className="fgc__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="fgc__navbar-menu_container">
          <div className="fgc__navbar-menu_container-links">
            <p><Link to="wfgc" spy smooth duration={500}>Tokenomics</Link></p>
            <p><a href="https://solidity.finance/audits/ForgottenCoinICO/" target="_blank" rel="noreferrer">Audit</a></p>
            <p><Link to="possibility" spy smooth duration={500}>NFT</Link></p>
            <p><Link to="blog" spy smooth duration={500}>Media</Link></p>
            <p><Link to="team" spy smooth duration={500}>Team</Link></p>
            <p><Link to="roadmap" spy smooth duration={500}>Roadmap</Link></p>
          </div>
          {/* <div className="fgc__navbar-menu_container-links-sign">
            <Web3ReactProvider getLibrary={getLibrary}>
              <div className="flex space-x-3">
                <Web3ReactConnectionComponent />
              </div>
            </Web3ReactProvider>
          </div> */}
        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
