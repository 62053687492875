import React from 'react';
import './team.css';

const Team = ({ imgPhoto, title, job, linkedin }) => (
  <a target="_blank" rel="noreferrer" href={linkedin} className="cardParent">
    <div className="w-52 bg-gray-900 rounded-xl sahdow-lg p-4 flex flex-col justify-center items-center cardTeam">
      <div className="mt-0 align-text-top">
        <img className="sc-jSgvzq jxYUkS object-center object-cover rounded-full h-24 w-24 boxShadowImg" src={imgPhoto} />
      </div>
      <div className="text-center mt-4">
        <p className="text-xl text-white font-bold mb-2 fontTitle">{title}</p>
        <p className="text-base text-gray-400 font-normal">&nbsp;{job}&nbsp;</p>
      </div>
    </div>
  </a>
);

export default Team;
