import React from 'react';
import cards from '../../assets/cards.png';
import bussiness from '../../assets/bussiness.png';
import './possibility.css';

const Possibility = () => (
  <div className="possibility" id="possibility">
    <div className="fgc__possibility section__padding">
      <div className="fgc__possibility-image">
        <img src={cards} alt="cards" />
      </div>
      <div className="fgc__possibility-content">
        <h1 className="gradient__text">Forgotten Metaverse</h1>
        <h4>Trade Usable NFTs</h4>
        <p>Start building your collection of Equipments, Businesses and Characters that you will be able to use in the Metaverse and increase your profit margin.</p>
        <p>Legendary NFTs come with HUGE bonuses and can be unique to each collection.</p>
      </div>
    </div>
    <div className="fgc__possibility section__padding">
      <div className="fgc__possibility-content">
        <h1 className="gradient__text">Manage your bussiness</h1>
        <h4>Advanced building system </h4>
        <p>Start building your own bussiness and earn tokens from other players who are using your services.</p>
        <p>To build a bussiness you need to have a land plot.</p>
      </div>
      <div className="fgc__possibility-image">
        <img src={bussiness} alt="bussiness" />
      </div>
    </div>
  </div>
);

export default Possibility;
