import React from 'react';
import { chainlink, coinmarketcap, solidityFinance, icoHolder, topIcoList, icoListingOnline } from './imports';
import './brand.css';

const Brand = () => (
  <div className="fgc__brand section__padding">
    <a className="mediaImg" target="_blank" rel="noreferrer" href="https://twitter.com/chainlink/status/1578168024372285441?cxt=HHwWgsC4iZ3d4-YrAAAA">
      <img src={chainlink} />
    </a>
    <a className="mediaImg" target="_blank" rel="noreferrer" href="https://coinmarketcap.com/currencies/forgotten-chain/ico/">
      <img src={coinmarketcap} />
    </a>
    <a className="mediaImg" target="_blank" rel="noreferrer" href="https://solidity.finance/audits/ForgottenCoinICO/">
      <img src={solidityFinance} />
    </a>
    <a className="mediaImg" target="_blank" rel="noreferrer" href="https://icoholder.com/en/forgotten-chain-1019198">
      <img src={icoHolder} />
    </a>
    <a className="mediaImg" target="_blank" rel="noreferrer" href="https://topicolist.com/forgotten-chain/">
      <img src={topIcoList} />
    </a>
    <a className="mediaImg" target="_blank" rel="noreferrer" href="https://www.icolistingonline.com/ico/forgotten-chain/">
      <img src={icoListingOnline} />
    </a>
  </div>
);

export default Brand;
