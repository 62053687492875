import React from 'react';
import { Pie } from 'react-chartjs-2';
import Feature from '../../components/feature/Feature';
import './whatfgc.css';

export const data = {
  labels: ['DEV Team', 'Public Sale', 'Private Sale', 'Marketing', 'Aidrop Reserve', 'Advisors Reserve', 'Liquidity', 'Strategic Investors', 'VC Reserve', 'Ecosystem', 'Community Rewards', 'Foundation', 'Exchange Listing'],
  datasets: [
    {
      data: [10, 9, 1, 3, 2, 5, 3, 5, 5, 30, 7, 10, 10],
      backgroundColor: [
        '#9167df',
        '#5c27bc',
        '#bda3eb',
        '#a07be3',
        '#8253da',
        '#662bd1',
        '#5223a8',
        '#3e1a80',
        '#2a1257',
        '#4b209b',
        '#5524af',
        '#a482e4',
        '#804fda',
      ],
      borderColor: [
        'rgba(65,43,123, 1)',
        'rgba(120,119,179, 1)',
        'rgba(67,66,144, 1)',
        'rgba(118,98,172, 1)',
        'rgba(150,149,234, 1)',
        'rgba(57,56,135, 1)',
        'rgba(57,56,135, 1)',
        '#2d3b4a',
        '#2d3b4a',
        '#2d3b4a',
        '#2d3b4a',
        '#2d3b4a',
        '#2d3b4a',
      ],
      borderWidth: 1,
    },
  ],
};

const Whatfgc = () => (
  <div className="fgc__whatfgc section__padding section__padding2" id="wfgc">
    <div className="fgc__whatfgc-heading">
      <h1 className="gradient__text">Tokenomics</h1>
    </div>
    <center><h3>Listing price in USD: 1 FTC = 0.05 USD</h3></center>
    <div className="fgc__whatfgc-container">
      <Feature title="TOTAL SUPPLY" text="1,000,000,000" />
      <Feature title="PRIVATE SALE" text="10,000,000" />
      <Feature title="PUBLIC SALE" text="90,000,000" />
    </div>
    <div className="chartParent">
      <Pie height={520} data={data} options={{ responsive: true, maintainAspectRatio: false, title: { display: false }, plugins: { labels: { render: 'value', fontColor: '#ffffff', fontSize: 12 } }, legend: { display: true, position: 'bottom', labels: { padding: 40 } } }} />
    </div>
  </div>
);

export default Whatfgc;
