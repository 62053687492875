import React from 'react';
import Team from '../../components/team/Team';
import './teamContainer.css';

import nistor from '../../assets/team/alexandru_nistor.png';
import coriiu from '../../assets/team/david_coriiu.png';
import rusu from '../../assets/team/vlad_rusu.png';
import curte from '../../assets/team/iulian_curte.png';
import popescu from '../../assets/team/ionu_popescu.png';
import vladescu from '../../assets/team/andrei_vladescu.png';
import costandache from '../../assets/team/george_constandache.png';
import carina from '../../assets/team/carina_delea.png';
import rotariu from '../../assets/team/iustin_rotariu.png';
import duta from '../../assets/team/robert_duta.png';
import oltean from '../../assets/team/alin_oltean.png';
import ovidiu from '../../assets/team/ovidiu_nistor.png';

const TeamContainer = () => (
  <div className="fgc__whatfgc section__padding section__padding2" id="team">
    <div className="fgc__whatfgc-heading">
      <h1 className="gradient__text">Core Team</h1>
    </div>
    {/* <center><h3>Listing price in USD: 1 FTC = 0.03 USD</h3></center> */}
    <div className="fgc__whatfgc-container teamParent">
      <Team imgPhoto={nistor} title="Alexandru Nistor" job="CEO" linkedin="https://www.linkedin.com/in/alex-nistor/" />
      <Team imgPhoto={coriiu} title="David Coriiu" job="CTO" linkedin="https://www.linkedin.com/in/iustinian-david-coriiu-22314819a/" />
      <Team imgPhoto={rusu} title="Vlad Rusu" job="COO" linkedin="https://www.linkedin.com/in/vlad-rusu-773244158" />
      <Team imgPhoto={carina} title="Carina Delea" job="Marketing" linkedin="https://www.linkedin.com/in/carina-florentina-d-5b0a16204/" />
      <Team imgPhoto={curte} title="Iulian Curte" job="Developer" linkedin="https://www.linkedin.com/in/iulian-curte-39a2141b6/" />
      <Team imgPhoto={popescu} title="Ionut Popescu" job="Developer" linkedin="https://www.linkedin.com/in/ionut-popescu/" />
      <Team imgPhoto={vladescu} title="Andrei Vladescu" job="Developer" linkedin="https://www.linkedin.com/in/vl%C4%83descu-andrei-155b48140/" />
      <Team imgPhoto={duta} title="Robert Duta" job="Developer" linkedin="https://www.linkedin.com/in/robert-gabriel-49a2b021a/" />
      <Team imgPhoto={rotariu} title="Iustin Rotariu" job="DevOps" linkedin="https://www.linkedin.com/in/iustin-d%C4%83nu%C8%9B-8218931b3/" />
      <Team imgPhoto={costandache} title="George Costandache" job="2D/3D Artist" linkedin="https://www.linkedin.com/in/george-constandache-468937219/" />
      <Team imgPhoto={oltean} title="Alin Oltean" job="UI/UX Artist" linkedin="https://www.linkedin.com/in/alin-oltean-636aaa218/" />
      <Team imgPhoto={ovidiu} title="Ovidiu Nistor" job="Game Tester" />
    </div>
  </div>
);

export default TeamContainer;
