import React from 'react';
import { Link } from 'react-scroll';
import fgcLogo from '../../logo.png';
import fgcLogoWhite from '../../logoWhite.png';
import './footer.css';

const Footer = () => (
  <div className="fgc__footer section__padding section__padding2">
    <div className="fgc__footer-heading">
      <h1 className="gradient__text">Do you want to step into the future before others</h1>
    </div>
    <Link className="buyFTCbtn" to="home" spy smooth duration={500}>
      <div className="fgc__footer-btn">Buy FTC</div>
    </Link>
    <div className="fgc__footer-links">
      <a href="#home" className="fgc__footer-links_logo">
        <img src={fgcLogoWhite} alt="fgc_logo" />
        <p>Clarence Thomas Building, P.O. Box 4649, Road Town, Tortola, British Virgin Islands, <br /> All Rights Reserved</p>
      </a>
      <div className="fgc__footer-links_div">
        <a target="_blank" rel="noreferrer" href="https://sale.forgottenchain.com/"><p>Marketplace</p></a>
        <a target="_blank" rel="noreferrer" href="https://forgottenchain.com/social.html"><p>Social Media</p></a>
        <a target="_blank" rel="noreferrer" href="https://lite.forgottenchain.com/"><p>Lite Version</p></a>
        <a target="_blank" rel="noreferrer" href="https://landing.forgottenchain.com/"><p>Get Started</p></a>
      </div>
      <div className="fgc__footer-links_div">
        <h4>Company</h4>
        <a target="_blank" rel="noreferrer" href="https://forgottenchain.com/terms-and-conditions.html"><p>Terms & Conditions</p></a>
        <a target="_blank" rel="noreferrer" href="https://forgottenchain.com/privacy.html"><p>Privacy Policy</p></a>
        <a target="_blank" rel="noreferrer" href="https://forgottenchain.com/SAFT.html"><p>SAFT(Simple Agreement for Future Tokens)</p></a>
      </div>
      <div className="fgc__footer-links_div">
        <h4>Get in touch</h4>
        <p>Clarence Thomas Building, P.O. Box 4649, Road Town, Tortola, British Virgin Islands</p>
        <p>contact@forgottenchain.com</p>
      </div>
    </div>

    <div className="fgc__footer-copyright">
      <p>@2022 Forgotten Studio Ltd. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
