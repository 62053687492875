import { useWeb3React } from '@web3-react/core';
import './wallet.css';
import { React, useState, useEffect } from 'react';// Fragment
import { Dialog } from '@headlessui/react';// Transition
import { ethers } from 'ethers';
import { NotificationManager } from 'kissu-react-notifications';
import NumericInput from 'react-numeric-input';
import axios from 'axios';
import { ReactComponent as MetaMaskSVG } from '../../assets/metamask.svg';
import { ReactComponent as WalletConnectSVG } from '../../assets/walletconnect.svg';
import 'kissu-react-notifications/lib/notifications.css';
import {
  injected,
  walletconnect,
  resetWalletConnector,
  // walletlink,
} from './Helpers/connectors';
import bnb from '../../assets/buyModal/bnb.png';
import ftc from '../../assets/buyModal/ftc.png';

export const contractAddress = '0xD1b944ba09e1D7d6934e9C411B49a28A7d55698A';

const contractAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_beneficiary',
        type: 'address',
      },
    ],
    name: 'buyTokens',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
    payable: true,
  },
];

let bReload = false;

const Web3ReactConnectionComponent = () => {
  /* connector, library, chainId, account, activate, deactivate */
  const web3reactContext = useWeb3React();
  const [isOpen, setIsOpen] = useState(false);

  /* web3react metamask */
  const connectMetamaskSimple = async () => {
    try {
      await web3reactContext.activate(injected);
      setIsOpen(true);
      const walletType = localStorage.getItem('walletType');
      if (walletType !== 'metamask') {
        localStorage.setItem('walletType', 'metamask');
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  /* web3react walletconnect */
  const connectWalletConnectSimple = async () => {
    try {
		const refreshCount = localStorage.getItem('refreshCount');
      if (refreshCount.toString() === '0') {
        resetWalletConnector(walletconnect);
        await web3reactContext.activate(walletconnect);
        localStorage.setItem('walletType', 'walletConnect');
        localStorage.setItem('refreshCount', '1');
        window.location.reload();
      } else if (refreshCount.toString() === '1') {
        resetWalletConnector(walletconnect);
        await web3reactContext.activate(walletconnect);
        localStorage.setItem('walletType', 'walletConnect');
        setIsOpen(true);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const reloadProvider = async () => {
    if (!bReload) {
      bReload = true;
    } else {
      return;
    }
    const refreshCount = await localStorage.getItem('refreshCount');
    if (!refreshCount) {
      localStorage.setItem('refreshCount', 0);
    }

    const walletType = await localStorage.getItem('walletType');
    if (walletType === 'walletConnect') {
      if (refreshCount.toString() === '1') {
        connectWalletConnectSimple();
      }
    } else if (walletType === 'metamask') {
      connectMetamaskSimple();
    }
  };

  reloadProvider();

  const [saftCheck, setSaftCheck] = useState(false);
  const [canSpend, setCanSpend] = useState(0);
  const [buyBNB, setbuyBNB] = useState(3);
  const [balance, setBalance] = useState(0);
  const [kyc, setKyc] = useState(0);
  const [minCap, setMinCap] = useState(false);
  const [calculateFTC, setCalculateFTC] = useState(0);
  /* web3react */
  const writeToContractUsingWeb3React = async () => {
    NotificationManager.warning('Due to market conditions, the ICO contract is paused for the moment! We are in negotiations with several launchpads to assist and secure a successful token launch!', 'Paused ICO', 3000);

    // if (!saftCheck) {
    //   NotificationManager.warning('In order to participate you must agree Simple Agreement For Future Tokens.', 'SAFT Required', 3000);
    //   return;
    // }
    // if (kyc === 0) {
    //   NotificationManager.warning('Please complete the KYC to be able to buy FTC.', 'Perform KYC', 3000);
    //   return;
    // }
    // if (kyc === 2) {
    //   NotificationManager.warning('US, China, Russia and North Korea are banned.', 'Your country is banned', 3000);
    //   return;
    // }
    // if (buyBNB > balance) {
    //   NotificationManager.error('You do not have enought BNB in your balance.', 'Insufficient funds', 3000);
    //   return;
    // }
    // if (!minCap && buyBNB < 0.2) {
    //   NotificationManager.error('Your first FTC payment has to be higher than 0.2 BNB.', 'Mininum is 0.2 BNB', 3000);
    //   return;
    // }
    // try {
    //   const weiAmount = ethers.utils.parseEther('' + buyBNB);

    //   const signer = web3reactContext.library.getSigner();
    //   const contract = new ethers.Contract(
    //     contractAddress,
    //     contractAbi,
    //     signer,
    //   );

    //   const options = { from: web3reactContext.account, value: weiAmount };

    //   const response = await contract.buyTokens(
    //     web3reactContext.account,
    //     options,
    //   );
    //   if (response.hash) {
    //     NotificationManager.success('', 'Success!  Check the transaction on bscScan!', 5000, null, true, 'You have purchased ' + (calculateFTC + '').substring(0, 10) + ' FTC. Transaction hash: <a target="_blank" href="https://bscscan.com/tx/' + response.hash + '">' + response.hash + '</a>.');
    //     setCanSpend(canSpend - buyBNB);
    //   }
    // } catch (ex) {
    //   NotificationManager.error(ex.message, 'Error', 3000);
    // }
  };

  const disconnectMetamaskSimple = () => {
    try {
      web3reactContext.deactivate();
      localStorage.setItem('refreshCount', 0);
      localStorage.removeItem('walletType');
      // localStorage.clear();
    } catch (ex) {
      console.log(ex);
    }
  };

  /* web3react coinbase */
  // const connectCoinbaseSimple = async () => {
  //   try {
  //     await web3reactContext.activate(walletlink);
  //   } catch (ex) {
  //     console.log(ex);
  //   }
  // };

  const [boughtFTC, setBoughtFTC] = useState(0);
  const [boughtBNB, setBoughtBNB] = useState(0);
  const [bnbPrice, setBnbPrice] = useState(0);
  function getBnbPrice(localBuyBNB) {
    axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin')
      .then((res) => {
        if (res && res.data && res.data[0]) {
          if ('current_price' in res.data[0]) {
            const price = res.data[0].current_price;
            setBnbPrice(price);
            if (price > 0) {
              setCalculateFTC((localBuyBNB * price) / 0.03);
            } else {
              setCalculateFTC(0);
            }
          }
        }
      });
  }

  useEffect(() => {
    getBnbPrice(buyBNB);
    const interval = setInterval(() => {
      getBnbPrice(buyBNB);
    }, 10000);
    return () => clearInterval(interval);
  }, [buyBNB]);

  function getIcoData(address, localBalance) {
    axios.get('https://lite.forgottenchain.com/api/ico/?address=' + address)
      .then((res) => {
        if ('kyc' in res.data) {
          const d = res.data;
          setKyc(d.kyc);
          setBoughtFTC(d.boughtFTC);
          setBoughtBNB(d.boughtBNB);
          if (localBalance > 0) {
            if (localBalance > d.canSpend) setCanSpend(d.canSpend); else setCanSpend(balance);
          } else {
            setCanSpend(0);
          }
          setMinCap(d.minCap);
          if (!saftCheck && d.minCap) {
            setSaftCheck(d.minCap);
          }
        }
      });
  }
  function setBnbInput(value, localCanSpend, localBnbPrice) {
    if (value >= localCanSpend && localCanSpend > 0) {
      value = localCanSpend;
    }
    setbuyBNB(value);
    if (localBnbPrice > 0) {
      setCalculateFTC((value * localBnbPrice) / 0.03);
    } else {
      setCalculateFTC(0);
    }
  }

  const getBalance = async (localAccount, first = false) => {
    if (localAccount) {
    // setBalance(0);
      web3reactContext.library.getBalance(localAccount).then((result) => {
        setBalance(result / 1e18);
        let newValue = result / 1e18;
        newValue -= (newValue / 300);
        if (first) setBnbInput(newValue, canSpend, bnbPrice);
      });
    }
  };

  useEffect(() => {
    if (web3reactContext.account) {
      getIcoData(web3reactContext.account, balance);
      getBalance(web3reactContext.account, true);
    }
    const interval = setInterval(() => {
      if (web3reactContext.account) {
        getIcoData(web3reactContext.account, balance);
        getBalance(web3reactContext.account);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [web3reactContext.account, balance]);

  function openModal() {
    getBalance(web3reactContext.account);
    setIsOpen(true);
  }

  return (
    <div>
      {!web3reactContext.account && (
        <>
          <button
            type="button"
            onClick={openModal}
          >
            Connect Wallet
          </button>
          <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-10 overflow-y-auto">
            <div className="min-h-screen px-4 text-center">
              <Dialog.Overlay className="fixed inset-0 bg-black/80" />
              <span className="inline-block h-screen align-middle" aria-hidden="true" />
              <Dialog.Panel className="relative inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <button type="button" className="absolute top-6 right-5 px-4" onClick={() => setIsOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-gray-500 "><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
                </button>
                <Dialog.Title className="flex justify-between text-md font-medium leading-6 text-gray-900">Connect to a Wallet</Dialog.Title>
                <div className="mt-2"><p className="text-sm text-gray-500">Connect to a wallet to access your funds. You can connect to a wallet that you have previously connected to.</p></div>
                <div className="flex items-center justify-between px-4 py-3 bg-white border-b border-gray-200">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <MetaMaskSVG />
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium leading-6 text-gray-900">Metamask</p>
                    </div>
                  </div>
                  <div className="ml-auto"><button type="button" onClick={() => { connectMetamaskSimple(); setIsOpen(false); setTimeout(() => { setIsOpen(true); }, 500); }}><span className="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-indigo-600 transition duration-150 ease-in-out border border-indigo-600 rounded-md hover:text-white hover:bg-indigo-600 "> Connect </span></button></div>
                </div>
                <div className="flex items-center justify-between px-4 py-3 bg-white border-b border-gray-200">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <WalletConnectSVG />
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium leading-6 text-gray-900">Wallet Connect</p>
                    </div>
                  </div>
                  <div className="ml-auto"><button type="button" onClick={() => { connectWalletConnectSimple(); setIsOpen(false); setTimeout(() => { setIsOpen(true); }, 500); }}><span className="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-indigo-600 transition duration-150 ease-in-out border border-indigo-600 rounded-md hover:text-white hover:bg-indigo-600 "> Connect</span></button></div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </>
      )}
      {web3reactContext.account && (
        <>
          <button
            type="button"
            onClick={openModal}
            className="addressBtn"
          >
            {web3reactContext.account.substring(0, 6)}...{web3reactContext.account.substr(-4)}
          </button>
          <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-10 overflow-y-auto">
            <div className="min-h-screen px-4 text-center">
              <Dialog.Overlay className="fixed inset-0 bg-black/80" />
              <span className="inline-block h-screen align-middle" aria-hidden="true" />
              <Dialog.Panel className="relative inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <button type="button" className="absolute top-6 right-5 px-4" onClick={() => setIsOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-gray-500 "><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
                </button>
                <Dialog.Title className="text-md leading-6 text-gray-900 font600 px-4 divRow">Wallet {web3reactContext.account && (
                <p className="connected">&nbsp;Connected</p>
                )}
                </Dialog.Title>
                {(localStorage.getItem('walletType') === 'metamask' || localStorage.getItem('walletType') === 'walletConnect') && (
                <div>
                  <div className="flex items-center justify-between px-4 py-3 bg-white border-b border-gray-200">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        {localStorage.getItem('walletType') === 'metamask' && (
                          <MetaMaskSVG />
                        )}
                        {localStorage.getItem('walletType') === 'walletConnect' && (
                          <WalletConnectSVG />
                        )}
                      </div>
                      <div className="ml-3">
                        <p className="text-base leading-6 text-gray-900 userAddress">{web3reactContext.account.substring(0, 6)}...{web3reactContext.account.substr(-4)}</p>
                        <div className="userBtn">
                          <div onClick={() => { navigator.clipboard.writeText(web3reactContext.account); NotificationManager.info(web3reactContext.account, 'The address has been copied to clipboard.', 3000); }} className="userBtnCopy">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>
                            <div>Copy</div>
                          </div>
                          <a target="_blank" rel="noreferrer" href={'https://bscscan.com/address/' + web3reactContext.account} className="userBtnBsc">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg>
                            <div className="userBtnBsc">Bsc Scan</div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="ml-auto"><button type="button" onClick={disconnectMetamaskSimple}><span className="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-indigo-600 transition duration-150 ease-in-out border border-indigo-600 rounded-md hover:text-white hover:bg-indigo-600 "> Disconnect </span></button></div>
                  </div>
                  <div className="parentBuy">
                    <div className="childBuy">
                      <div className="parentCoin">
                        <div className="balance">
                          <img src={bnb} alt="" />
                          <span className="parentBalanceBNB"><p className="BNBprice">&nbsp;BNB</p></span>
                          <div className="balanceValue">{balance.toString().substring(0, 10)}</div>
                        </div>
                        <div className="balance">
                          <img src={ftc} alt="" />
                          <span className="parentBalanceBNB"><p className="FTCprice">&nbsp;F<p className="FTCpriceletter">T</p>C</p></span>
                          <div className="balanceValue">{boughtFTC.toString().substring(0, 10)}</div>
                        </div>
                      </div>
                      <div className="flex walletConnectBtn parentBuy">
                        <NumericInput step={0.001} min={0.05} precision={7} value={buyBNB} max={canSpend} className="bnbInput" strict onChange={(value) => setBnbInput(value, canSpend, bnbPrice)} />
                        { minCap && (
                          <div className="calculateFTC">Resulted FTC: {calculateFTC.toString().substring(0, 10)}</div>
                        )}
                        { !minCap && (
                          <div className="calculateFTCpaddingBot">Resulted FTC: {calculateFTC.toString().substring(0, 10)}</div>
                        )}
                        { minCap && (
                          <button
                            type="button"
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                            onClick={writeToContractUsingWeb3React}
                          >
                            Buy FTC
                          </button>
                        )}
                      </div>
                      { !minCap && (
                        <div className="parentSAFT">
                          <label htmlFor="saft-check" className="parentCheckSaft">
                            <input
                              type="checkbox"
                              id="saft-check"
                              checked={saftCheck}
                              onChange={() => { if (!saftCheck) setSaftCheck(true); }}
                              required
                              className="inputSaft"
                            />
                            I agree with the <a href="https://forgottenchain.com/SAFT.html" target="_blank" rel="noreferrer" className="saft-url">SAFT</a>.
                          </label>
                          <div className="buyFTCabs">
                            <button
                              type="button"
                              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                              onClick={writeToContractUsingWeb3React}
                            >
                              Buy FTC
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                </div>
                )}
              </Dialog.Panel>
            </div>
          </Dialog>
        </>
      )}
      {(web3reactContext.account && kyc === 1) && (
        <div className="flex space-x-3 walletConnectBtn width100 scale-up-center">
          <button
            type="button"
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded width100 buyBtn"
            onClick={openModal}
          >
            Buy FTC
          </button>
        </div>
      )}
      {(web3reactContext.account && kyc === 0) && (/* kyc":1, // 0 - nu a facut kyc | 1 - kyc bun | 2 - valid, dar tara interzisa */
        <div className="kycModal">
          <p>KYC Verification</p>
          <br />
          <p>Please complete the KYC to be able to buy FTC</p>
          <br />
          <div className="kycBoxElem">
            <p>🔴 Not submitted</p>
          </div>
          <a target="_blank" rel="noreferrer" href="https://kyc.forgottenchain.com/"><button type="button">Complete KYC</button></a>
        </div>
      )}
      {(web3reactContext.account && kyc === 2) && (
        <div className="kycModal">
          <p>KYC Verification</p>
          <br />
          <p>US, China, Russia and North Korea are banned.</p>
          <br />
          <div className="kycBoxElem">
            <p>🔴 Your country is banned</p>
          </div>
          <a target="_blank" rel="noreferrer" href="https://kyc.forgottenchain.com/"><button type="button">Complete KYC</button></a>
        </div>
      )}
      {!web3reactContext.account && (
        <div className="kycModal">
          <p>KYC Verification</p>
          <br />
          <p>Please complete the KYC to be able to buy FTC</p>
          <br />
          <div className="kycBoxElem">
            <p>🔴 Wallet not connected!</p>
          </div>
        </div>
      )}
      {/* {web3reactContext.account && (
        <div className="flex space-x-3 walletConnectBtn">
          <button
            type="button"
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            onClick={disconnectMetamaskSimple}
          >
            Disconnect
          </button>
        </div>
      )} */}
      {/* {!web3reactContext.account && (
        <div className="flex space-x-3 walletConnectBtn">
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={connectCoinbaseSimple}
          >
            CoinbaseWallet
          </button>
        </div>
      )} */}
    </div>
  );
};
export default Web3ReactConnectionComponent;
