import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
// import { WalletLinkConnector } from '@web3-react/walletlink-connector';

// const RPC_URLS = {
//   56: 'https://bsc-dataseed.binance.org/',
// };

/* metamask */
export const injected = new InjectedConnector({
  supportedChainIds: [56],
});

export const walletconnect = new WalletConnectConnector({
  rpc: {
    56: 'https://bsc-dataseed.binance.org/',
  },
  qrcode: true,
  pollingInterval: 150000,
});

export function resetWalletConnector(connector) {
  if (connector && connector instanceof WalletConnectConnector) {
    connector.walletConnectProvider = undefined;
  }
}

/* coinbase */
// export const walletlink = new WalletLinkConnector({
//   url: RPC_URLS[56],
//   appName: 'demo-app',
//   supportedChainIds: [56],
// });
