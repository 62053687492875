import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05, blog06 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="fgc__blog section__padding section__padding2" id="blog">
    <div className="fgc__blog-heading">
      <h1 className="gradient__text">A lot is happening, <br /> We are blogging about it.</h1>
    </div>
    <div className="fgc__blog-container">
      <div className="fgc__blog-container_groupA">
        <Article imgUrl={blog01} date="Sep 26, 2021" text="Forgotten Chain showcasing the first ingame pre-alpha footage with a live gameplay demo!" linkUrl="https://www.youtube.com/watch?v=f4l65vYICaQ" />
      </div>
      <div className="fgc__blog-container_groupB">
        <Article imgUrl={blog06} date="Oct 7, 2022" text="We're happy to announce that ForgottenChain has integrated Chainlink Price Feeds and Verifiable Random Function (VRF) on BNB Chain to help generate ForgottenChain NFTs within our Wheel of Fortune. " linkUrl="https://twitter.com/chainlink/status/1578168024372285441?cxt=HHwWgsC4iZ3d4-YrAAAA" />
        <Article imgUrl={blog02} date="April 15, 2022" text="The video posted by Alexandrus has just broken the 100k views barrier! Excited for the things to come." linkUrl="https://www.youtube.com/watch?v=YIjnPw7FxBw" />
        <Article imgUrl={blog03} date="April 11, 2022" text="Professor Crypto has done an overview of the Forgotten Chain Project! Tune in for the latest updates!" linkUrl="https://www.youtube.com/watch?v=U-9W9F6euLA" />
        {/* <Article imgUrl={blog04} date="April 12, 2022" text="Youtuber Crypto Pablo covered the Forgotten Chain project in one of his latest videos. Check it out!" linkUrl="https://www.youtube.com/watch?v=EeexUTjj8Bo" /> */}
        <Article imgUrl={blog05} date="April 12, 2022" text="We've just listed our Token, the FTC, on CoinMarketCap. The leading token listing website in the crypto industry." linkUrl="https://coinmarketcap.com/currencies/forgotten-chain/ico/" />
      </div>
    </div>
  </div>
);

export default Blog;
