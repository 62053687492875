import React from 'react';
import './roadmap.css';

const Roadmap = () => (
  <div className="fgc__roadmap section__padding section__padding2" id="roadmap">
    <div className="fgc__roadmap-heading">
      <h1><span className="gradient__text">Project</span> Roadmap</h1>
    </div>
    <p className="max-w-xl mx-auto text-lg font-light  subtitle3 centerImportant font-syne">With the roadmap, we aim to offer a concise picture of how the project is expected to progress. We want to release the playable Alpha as soon as possible and start de Play2Earn Economy within the main game.</p>
    <div className="relative max-w-xl mx-auto my-16 lg:max-w-3xl before:absolute before:top-0 before:w-1 before:h-full before:left-2 roadmapLine lg:before:left-1/2">
      <div className="relative pl-8 mb-12 lg:flex even:flex-row-reverse">
        <div className="absolute top-0 left-0 w-5 h-5 border-4 rounded-full lg:left-1/2 lg:-translate-x-2 border-primary-gradientFrom bg-primary-gradientTo " />
        <p className="roadmapDate text-xl font-nunito tracking-wide  lg:w-1/2  text-primary-gradientTo  lg:mt-px lg:text-right  lg:pr-8">Q1 2021</p>
        <div className="lg:w-1/2 lg:text-left lg:ml-5">
          <h4 className="mt-4 text-xl font-light text-white lg:mt-0 font-nunito">Phase 1</h4>
          <div className="mt-2 leading-7 text-gray-300 font-syne">
            <ul>
              <li>Gathering the team</li>
              <li>Game Development Begins</li>
              <li>Community Planning</li>
              <li>Implementation with BSC</li>
              <li>Early prototyping</li>
              <li>Milestone Scheduling</li>
              <li />
            </ul>
          </div>
        </div>
      </div>
      <div className="relative pl-8 mb-12 lg:flex even:flex-row-reverse">
        <div className="absolute top-0 left-0 w-5 h-5 border-4 rounded-full lg:left-1/2 lg:-translate-x-2 border-primary-gradientFrom bg-primary-gradientTo " />
        <p className="roadmapDate text-xl font-nunito tracking-wide  lg:w-1/2  text-primary-gradientTo  lg:mt-px lg:text-left  lg:pl-3">Q2 2021</p>
        <div className="lg:w-1/2 lg:text-right lg:pr-10">
          <h4 className="mt-4 text-xl font-light text-white lg:mt-0 font-nunito">Phase 2</h4>
          <div className="mt-2 leading-7 text-gray-300 font-syne">
            <ul>
              <li>Website 1.0 release</li>
              <li>Whitepaper 1.0 release</li>
              <li>Social media presence</li>
              <li>Promotional Campaigns & Community Building</li>
              <li>Token Planning</li>
              <li>Releasing teasers & leaks</li>
              <li />
            </ul>
          </div>
        </div>
      </div>
      <div className="relative pl-8 mb-12 lg:flex even:flex-row-reverse">
        <div className="absolute top-0 left-0 w-5 h-5 border-4 rounded-full lg:left-1/2 lg:-translate-x-2 border-primary-gradientFrom bg-primary-gradientTo  " />
        <p className="roadmapDate text-xl font-nunito tracking-wide  lg:w-1/2  text-primary-gradientTo  lg:mt-px lg:text-right  lg:pr-8">Q3 2021</p>
        <div className="lg:w-1/2 lg:text-left lg:ml-5">
          <h4 className="mt-4 text-xl font-light text-white lg:mt-0 font-nunito">Phase 3</h4>
          <div className="mt-2 leading-7 text-gray-300 font-syne">
            <ul>
              <li>First NFT sale ( 50% discount )</li>
              <li>Community rewards with NFTs</li>
              <li>In-game footage and leaks</li>
              <li>Lite Version planning</li>
              <li>Support for other wallets</li>
              <li>Tokenomics & Smart contracts created</li>
              <li />
            </ul>
          </div>
        </div>
      </div>
      <div className="relative pl-8 mb-12 lg:flex even:flex-row-reverse">
        <div className="absolute top-0 left-0 w-5 h-5 border-4 rounded-full lg:left-1/2 lg:-translate-x-2 border-primary-gradientFrom bg-primary-gradientTo  " />
        <p className="roadmapDate text-xl font-nunito tracking-wide  lg:w-1/2  text-primary-gradientTo  lg:mt-px lg:text-left  lg:pl-3">Q4 2021</p>
        <div className="lg:w-1/2 lg:text-right lg:pr-10">
          <h4 className="mt-4 text-xl font-light text-white lg:mt-0 font-nunito">Phase 4</h4>
          <div className="mt-2 leading-7 text-gray-300 font-syne">
            <ul>
              <li>Lite version release</li>
              <li>Security Audit succesfully passed</li>
              <li>Website 2.0 release</li>
              <li>Lite version 2.0 release</li>
              <li>Land system prototype</li>
              <li>Graphics Improvement - AAA Level</li>
              <li>ForgottenChain Internal Alpha</li>
              <li>ForgottenVerse - Metaverse Planning</li>
              <li />
            </ul>
          </div>
        </div>
      </div>
      <div className="relative pl-8 mb-12 lg:flex even:flex-row-reverse">
        <div className="absolute top-0 left-0 w-5 h-5 border-4 rounded-full lg:left-1/2 lg:-translate-x-2 border-primary-gradientFrom bg-primary-gradientTo  " />
        <p className="roadmapDate text-xl font-nunito tracking-wide  lg:w-1/2  text-primary-gradientTo  lg:mt-px lg:text-right  lg:pr-8">Q1 2022</p>
        <div className="lg:w-1/2 lg:text-left lg:ml-5">
          <h4 className="mt-4 text-xl font-light text-white lg:mt-0 font-nunito">Phase 5</h4>
          <div className="mt-2 leading-7 text-gray-300 font-syne">
            <ul>
              <li>ForgottenValley - Planning</li>
              <li>Character Customization</li>
              <li>Land gameplay internal alpha</li>
              <li>Metaverse integration - All games in one application</li>
              <li>NFT re-design & distribution</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="relative pl-8 mb-12 lg:flex even:flex-row-reverse">
        <div className="absolute top-0 left-0 w-5 h-5 border-4 rounded-full lg:left-1/2 lg:-translate-x-2 border-primary-gradientFrom bg-primary-gradientTo  " />
        <p className="roadmapDate text-xl font-nunito tracking-wide  lg:w-1/2  text-primary-gradientTo  lg:mt-px lg:text-left  lg:pl-3">Q2 2022</p>
        <div className="lg:w-1/2 lg:text-right lg:pr-10">
          <h4 className="mt-4 text-xl font-light text-white lg:mt-0 font-nunito">Phase 6</h4>
          <div className="mt-2 leading-7 text-gray-300 font-syne">
            <ul>
              <li>Community Rework</li>
              <li>Initial Coin Offering - Forgotten Coin </li>
              <li>NFT Sale ( 25% discount )</li>
              <li>Closed Public Alpha - ForgottenChain</li>
              <li>Land sale ( 50% discount )</li>
              <li />
            </ul>
          </div>
        </div>
      </div>
      <div className="relative pl-8 mb-12 lg:flex even:flex-row-reverse">
        <div className="absolute top-0 left-0 w-5 h-5 border-4 rounded-full lg:left-1/2 lg:-translate-x-2 border-primary-gradientFrom bg-purple-900  " />
        <p className="roadmapDate text-xl font-nunito tracking-wide  lg:w-1/2  text-primary-gradientTo  lg:mt-px lg:text-right  lg:pr-8">Q3 2022</p>
        <div className="lg:w-1/2 lg:text-left lg:ml-5">
          <h4 className="mt-4 text-xl font-light text-white lg:mt-0 font-nunito">Phase 7</h4>
          <div className="mt-2 leading-7 text-gray-300 font-syne">
            <ul>
              <li>Play-to-earn release</li>
              <li>ForgottenValley - Development</li>
              <li>NFT Marketplace release</li>
              <li>Open Public Alpha - ForgottenChain</li>
              <li />
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Roadmap;
