import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="fgc__cta">
    <div className="fgc__cta-content">
      <p>Request Early Access to Get Started</p>
      <h3>Register Today & start exploring the Forgotten Metaverse.</h3>
    </div>
    <div className="fgc__cta-btn">
      <a target="_blank" rel="noreferrer" href="https://sale.forgottenchain.com">
        <button type="button">Get Started</button>
      </a>
    </div>
  </div>
);

export default CTA;
